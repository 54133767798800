<template >
    <div v-if="spinner==true" class="spinner"></div>
    <div :class="spinner == true?'difuminado':''">
        <div class="card">
            <div class="card-header">
                <p class="card-title"> Facturación Albaranes</p>
            </div>
            <div class="card-body">
                    <div class="row"> 
                        <div class="col-md-4">
                            <label for="">Empresa</label>
                            <select v-model="empresaselec" class="form-control" name="" id="empr">
                                <option value="">Seleccione empresa</option>
                                <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                            </select>
                        </div>    
                        <div class="col-md-4">
                            <label for="">Compañía</label>
                            <MultiSelect style="width: 100%;" v-model="companiasSeleccionadas" optionLabel="nombre" :options="companias" filter
                                :maxSelectedLabels="1" class="w-full md:w-20rem" placeholder="Seleccione compañía">
                                <template #option="slotProps">
                                    {{ slotProps.option.nombre }}({{slotProps.option.prefijo}})
                                </template>
                            </MultiSelect>
                        </div>    
                        <div class="col-md-4 row" style="gap: 30px;">
                            <div>
                                <label for="">Fecha inicio</label>
                                <input class="form-control" type="date" v-model="fecha_inicio">
                            </div>
                            <div>
                                <label for="">Fecha fin</label>
                                <input class="form-control" type="date" v-model="fecha_fin">
                            </div>
                        </div>                               
                    </div>
            </div>
        </div>
        <div class="card card-default card-tabs">
            <div class="card-header p-1 pt-1">
                <ul class="nav nav-pills" ref="tabHeaders">
                    <li  class="nav-item">
                        <a class="nav-link active" href="#busqueda" data-toggle="tab">
                            Búsqueda
                        </a>
                    </li>
                    <li  class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#expedientes">
                            Números expedientes
                        </a>
                    </li>
                    <li v-if="mostrarCSV" class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#importcsv">
                            Importación CSV
                        </a>
                    </li>
                    <li v-if="mostrarCSVLD"  class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#importcsvld">
                            Importación CSV LD
                        </a>
                    </li>
                    <li v-if="mostrarPDF"  class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#importpdf">
                            Importación PDF
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" ref="tabContents">
                    <div class="tab-pane active container-grid" id="busqueda">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">
                                        Número Albarán
                                    </th>
                                    <th style="text-align: center;">
                                        Facturado
                                    </th>
                                    <th style="text-align: center;">
                                        Búsqueda Nombre
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="text-align: center;">
                                        <input v-model="numeroAlbaran" class="form-control" type="text">
                                    </td>
                                    <td style="text-align: center;">
                                        <select class="form-control" v-model="facturadoselec" id="">
                                            <option value="">Todos</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                        </select>
                                    </td>
                                    <td  style="text-align: center;">
                                        <input class="form-control" v-model="busquedaNombre" type="text">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display: flex; justify-content: center;">
                            <button @click="mostrarAlbaranesBusqueda" class="btn btn-sm btn-primary">Mostrar</button>
                        </div>
                    </div>
                    <div class="tab-pane container-grid" id="expedientes">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">
                                        Número de expedientes (separados por comas)
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="display: flex;justify-content: center; gap: 20px;">
                                        <textarea style="width: 50%;" class="form-control" v-model="numerosExpedientes"> </textarea>
                                        <div v-if="mostrarrelacionados" class="row" style="align-items: center; gap: 5px;">
                                            <label style="margin: 0;" for="">Incluir relacionados</label>
                                            <input type="checkbox" v-model="incluirelacionados">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display: flex; justify-content: center;">
                            <button @click="mostrarAlbaranesNumerosExpe" class="btn btn-sm btn-primary">Mostrar</button>
                        </div>
                    </div>
                    <div class="tab-pane container-grid" id="importcsv">
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="text-align: center;">Formato CSV</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="row">
                                            <div class="col-md-6">
                                                <img src="@/assets/img/ejemplo_importacion_albaranes_csv.png" alt="">
                                                <div>
                                                    <p>El CSV debe contener las siguientes columnas, separadas por punto y coma (;), por este orden:</p>
                                                    <ul>
                                                        <li>Nro. ENCARGO</li>
                                                        <li>Fecha Factura (formato: dd/mm/aaaa)</li>
                                                        <li>Número Factura (opcional. Si vacío, cogerá el siguiente de su línea de facturación) (no poner prefijo/sufijo)</li>
                                                        <li>Importe factura (con IVA)</li>
                                                        <li>Cobrado (SI/NO) (vacío implica NO)</li>
                                                        <li>Fecha cobro</li>
                                                        <li>Cliente: A (Asegurado), C (Compañía), vacío se factura como en el albarán</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Seleccionar serie de facturación para las facturas del CSV</p>
                                                <select style="width: 350px;" class="form-control" v-model="serieCSVseleccionada" id="">
                                                    <option value="">Seleccione serie</option>
                                                    <option v-for="serie in series" :key="serie.id" :value="serie.id">{{ serie.nombre_serie }}</option>
                                                </select>
                                                <p style="margin-top: 25px;">Seleccionar fichero de tipo CSV</p>
                                                <div class="row" style="gap: 15px;">
                                                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" chooseLabel="Elegir Archivo" />
                                                    <div v-if="mostraravisos" class="row" style="align-items: center; gap: 5px;">
                                                        <label for="" style="margin: 0;">Incluir avisos</label>
                                                        <input type="checkbox" v-model="incluiravisoscsv">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="display: flex; justify-content: center;">
                                <button @click="mostrarAlbaranesCSV" class="btn btn-sm btn-primary">Mostrar</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane container-grid" id="importcsvld">
                        
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="text-align: center;">Formato CSV-LD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="row">
                                            <div class="col-md-6">
                                                <img src="@/assets/img/ejemplo_importacion_albaranes_csv.png" alt="">
                                                <div>
                                                    <p>El CSV debe contener las siguientes columnas, separadas por punto y coma (;), por este orden:</p>
                                                    <ul>
                                                        <li>Nro. ENCARGO</li>
                                                        <li>Fecha Factura (formato: dd/mm/aaaa)</li>
                                                        <li>Número Factura (opcional. Si vacío, cogerá el siguiente de su línea de facturación) (no poner prefijo/sufijo)</li>
                                                        <li>Importe factura (con IVA)</li>
                                                        <li>Cobrado (SI/NO) (vacío implica NO)</li>
                                                        <li>Fecha cobro</li>
                                                        <li>Cliente: A (Asegurado), C (Compañía), vacío se factura como en el albarán</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Seleccionar serie de facturación para las facturas del CSV</p>
                                                <select style="width: 350px;" class="form-control" v-model="serieCSVLDseleccionada" id="">
                                                    <option value="">Seleccione serie</option>
                                                    <option v-for="serie in series" :key="serie.id" :value="serie.id">{{ serie.nombre_serie }}</option>
                                                </select>
                                                <p style="margin-top: 25px;">Seleccionar fichero de tipo CSV</p>
                                                <div class="row" style="gap: 15px;">
                                                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivold($event)" chooseLabel="Elegir Archivo" />
                                                    <div v-if="mostraravisos" class="row" style="align-items: center; gap: 5px;">
                                                        <label for="" style="margin: 0;">Incluir avisos</label>
                                                        <input type="checkbox" v-model="incluiravisoscsvld">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="display: flex; justify-content: center;">
                                <button @click="mostrarAlbaranesCSVLD" class="btn btn-sm btn-primary">Mostrar</button>
                            </div>
                        </div>
                    
                    </div>
                    <div class="tab-pane container-grid" id="importpdf">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">
                                        Importar expedientes desde el PDF
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="display: flex;justify-content: center; gap: 20px;">
                                        <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivoPdf($event)" chooseLabel="Elegir Archivo" />
                                        <div v-if="mostrarrelacionados" class="row" style="align-items: center; gap: 5px;">
                                            <label style="margin: 0;" for="">Incluir relacionados</label>
                                            <input type="checkbox" v-model="incluirelacionadospdf">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display: flex; justify-content: center;">
                            <button @click="mostrarAlbaranesPDF" class="btn btn-sm btn-primary">Mostrar</button>
                        </div>
                    </div>                
                </div>            
            </div>
        </div>
        <div class="card" v-if="albaranesVisibles">
            <div class="card-body">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style="text-align: center;">Nº Albarán</th>
                            <th style="text-align: center;">Fecha Albarán</th>
                            <th style="text-align: center;">Expediente</th>
                            <th style="text-align: center;">Cliente</th>
                            <th style="text-align: center;">Importe</th>
                            <th style="text-align: center;">Factura</th>
                            <th style="text-align: center;"><input v-model="checkTodas" type="checkbox"></th>
                            <th style="text-align: center;"></th>
                            <th style="text-align: center;">Eliminar factura</th>
                            <th style="text-align: center;">Abono</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="albaran in albaranes" :key="albaran.id_albaran">
                            <td>{{ albaran.numero }}</td>
                            <td>{{ albaran.fecha }}</td>
                            <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                            <td>{{ albaran.cliente }}</td>
                            <td>{{ albaran.importe_base }}€</td>
                            <td>{{ albaran.factura }}</td>
                            <td v-if="albaran.factura">
                                
                            </td>
                            <td v-else style="text-align: center;">
                                <input @change="marcado(albaran)" v-model="albaran.marcada" type="checkbox">
                            </td>
                            <td v-if="albaran.factura" style="text-align: center;">
                                <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                            </td>
                            <td v-else>
                                
                            </td>
                            <td v-if="albaran.factura" style="text-align: center;">
                                <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                            </td>
                            <td v-else>
                                
                            </td>
                            <td v-if="albaran.factura" style="text-align: center;">
                                <button @click="botonabonar(albaran)" class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                            </td>
                            <td v-else>
                                
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><strong>{{ importe_base }}€</strong></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card" v-if="albaranesCSVVisibles">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Nº Albarán</th>
                        <th>Fecha Albarán</th>
                        <th>Expediente</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Factura</th>
                        <th style="text-align: center;" ><input v-model="checkTodasCSV" type="checkbox"></th>
                        <th></th>
                        <th>Eliminar factura</th>
                        <th>Abono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="albaran in albaranesCSV" :key="albaran.id_albaran">
                        <td>{{ albaran.numero }}</td>
                        <td>{{ albaran.fecha }}</td>
                        <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                        <td>{{ albaran.cliente }}</td>
                        <td>{{ albaran.importe_base }}€</td>
                        <td>{{ albaran.factura }}</td>
                        <td v-if="albaran.factura">
                                
                        </td>
                        <td v-else style="text-align: center;">
                            <input @change="marcadoCSV(albaran)" v-model="albaran.marcada" type="checkbox">
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button @click="botonabonar(albaran)" class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                        </td>
                        <td v-else>
                            
                        </td>
                    </tr>
    
                    <tr style="background-color: crimson;" v-for="error in erroresCSV" :key="error">
                        <td style="color: white">
                            No coincide!
                        </td>
                        <td></td>
                        <td v-if="error.expediente" style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">
                            {{ error.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ error.relacionados }}</span>
                        </td>
                        <td v-else>
    
                        </td>
                        <td></td>
                        <td style="color: white">{{ error.importe_csv }}€</td>
                        <td v-if="error.numero" style="color: white">Nº{{ error.numero }} Fecha:{{ error.fecha }}</td>
                        <td v-else></td>
                        <td></td>
                        <td colspan="3" style="color: white">{{ error.error }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{ importe_base_csv }}€</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card" v-if="albaranesCSVLDVisibles">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Nº Albarán</th>
                        <th>Fecha Albarán</th>
                        <th>Expediente</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Factura</th>
                        <th style="text-align: center;"><input v-model="checkTodasCSVLD" type="checkbox"></th>
                        <th></th>
                        <th>Eliminar factura</th>
                        <th>Abono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="albaran in albaranesCSVLD" :key="albaran.id_albaran">
                        <td>{{ albaran.numero }}</td>
                        <td>{{ albaran.fecha }}</td>
                        <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                        <td>{{ albaran.cliente }}</td>
                        <td>{{ albaran.importe_base }}€</td>
                        <td>{{ albaran.factura }}</td>
                        <td v-if="albaran.factura">
                                
                        </td>
                        <td v-else style="text-align: center;">
                            <input @change="marcadoCSVLD(albaran)" v-model="albaran.marcada" type="checkbox">
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button @click="botonabonar(albaran)" class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                        </td>
                        <td v-else>
                            
                        </td>
                    </tr>
    
                    <tr style="background-color: crimson;" v-for="error in erroresCSVLD" :key="error">
                        <td style="color: white">
                            No coincide!
                        </td>
                        <td></td>
                        <td v-if="error.expediente" style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">
                            {{ error.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ error.relacionados }}</span>
                        </td>
                        <td v-else>
    
                        </td>
                        <td></td>
                        <td style="color: white">{{ error.importe_csv }}€</td>
                        <td v-if="error.numero" style="color: white">Nº{{ error.numero }} Fecha:{{ error.fecha }}</td>
                        <td v-else>
    
                        </td>
                        <td></td>
                        <td colspan="3" style="color: white">{{ error.error }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{ importe_base_csvld }}€</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card" v-if="albaranesPDFVisibles">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Nº Albarán</th>
                        <th>Fecha Albarán</th>
                        <th>Expediente</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Factura</th>
                        <th style="text-align: center;"><input v-model="checkTodasPDF" type="checkbox"></th>
                        <th></th>
                        <th>Eliminar factura</th>
                        <th>Abono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="albaran in albaranesPDF" :key="albaran.id_albaran">
                        <td>{{ albaran.numero }}</td>
                        <td>{{ albaran.fecha }}</td>
                        <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                        <td>{{ albaran.cliente }}</td>
                        <td>{{ albaran.importe_base }}€</td>
                        <td>{{ albaran.factura }}</td>
                        <td v-if="albaran.factura">
                                
                        </td>
                        <td v-else style="text-align: center;">
                            <input @change="marcadoPDF(albaran)" v-model="albaran.marcada" type="checkbox">
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button @click="botonabonar(albaran)" class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                        </td>
                        <td v-else>
                            
                        </td>
                    </tr>
    
                    <tr style="background-color: crimson;" v-for="error in erroresPDF" :key="error">
                        <td style="color: white">
                            No coincide!
                        </td>
                        <td></td>
                        <td v-if="error.expediente" style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">
                            {{ error.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ error.relacionados }}</span>
                        </td>
                        <td v-else>
    
                        </td>
                        <td></td>
                        <td style="color: white">{{ error.importe_pdf }}€</td>
                        <td v-if="error.numero" style="color: white">Nº{{ error.numero }} Fecha:{{ error.fecha }}</td>
                        <td v-else>
    
                        </td>
                        <td></td>
                        <td colspan="3" style="color: white">{{ error.error }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{ importe_base_pdf }}€</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Dialog :style="{ width: '25rem' }" @hide="cerrarForm" header="Eliminar factura" modal
        v-model:visible="dialogeliminar">
            <div>
                <textarea id="" style="width: 100%; margin: 10px 0" v-model="motivoEliminar" placeholder="Indique un motivo"></textarea>
            </div>
            <div style="display: flex; justify-content: end; gap: 15px;">
                <button @click="eliminarAlbaran" class="btn btn-sm btn-primary">Eliminar</button>
                <button @click="cerrarForm" class="btn btn-sm btn-light">Cancelar</button>
            </div>
        </Dialog>
        <Dialog @hide="cerrarFormAbonar" header="Abonar factura" modal
        v-model:visible="dialogabonar">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            Factura a abonar
                        </th>
                        <th>
                            Importe total
                        </th>
                        <th>
                            Ya abonado
                        </th>
                        <th>
                            Numero factura
                        </th>
                        <th>
                            Importe a abonar
                        </th>
                        <th>
                            IVA
                        </th>
                        <th>
                            Concepto
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ albaranaabonar.numero_factura }}
                        </td>
                        <td>
                            {{ albaranaabonar.importe_base }}
                        </td>
                        <td>
                            {{ albaranaabonar.importe_abonado }}
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="number" v-model="numeroFacturaAbonar">
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="number" v-model="importeFacturaAbonar">
                        </td>
                        <td>
                            <select class="form-control form-control-sm" v-model="ivaAbono" id="">
                                <option v-for="impuesto in impuestos" :key="impuesto.id" :value="impuesto.valor_iva">{{ impuesto.valor_iva }}</option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" v-model="conceptoFacturaAbonar">
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="display: flex; justify-content: end; gap: 15px;">
                <button @click="abonarFactura" class="btn btn-sm btn-primary">Abonar</button>
                <button @click="cerrarFormAbonar" class="btn btn-sm btn-light">Cancelar</button>
            </div>
        </Dialog>
        <div class="card" v-if="albaranesPDFVisibles">
            <div class="card-header p-1 pt-1">
                <ul class="nav nav-pills" ref="tabHeaders">
                    <li  class="nav-item">
                        <a class="nav-link active" href="#facturapdf" data-toggle="tab">
                            Crear factura
                        </a>
                    </li>
                </ul>
            </div>    
            <div class="card-body">
                <div class="tab-content" ref="tabContents">
                    <div class="tab-pane active container-grid" id="facturapdf">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Destinatario</th>
                                    <th>Empresa emisora</th>
                                    <th>Forma pago</th>
                                    <th>I.V.A.</th>
                                    <th>Nº Factura</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select v-model="destinatarioPDFFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cliente</option>
                                            <option v-for="comp in Object.entries(destinatariosPDFFactura)" :key="comp[0]" :value="comp[0]">{{ comp[1].nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="empresaPDFFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione empresa</option>
                                            <option v-for="empr in empresasPDFFactura" :key="empr.id" :value="empr.id">{{ empr.nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="formapagoPDFFactura" class="form-control" name="" id="">
                                            <option v-for="formas in formaspagoPDFFactura" :key="formas">{{ formas }}</option>
                                        </select>
                                        <select v-model="cuentaPDFFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cuenta</option>
                                            <option v-for="cuenta in cuentasPDFFactura" :value="cuenta.id_cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                        </select>
                                    </td>
                                    <td style="display: flex;">
                                        <select class="form-control" v-model="ivaPDFFactura" id="">
                                            <option v-for="iva in ivasPDFFactura" :key="iva" :value="iva">{{ iva }}%</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="row" v-if="destinatarioPDFFactura!=''">
                                            <select style="width: 200px;" class="form-control" v-model="seriePDFFactura" id="">
                                                <option v-for="serie in Object.values(seriesPDFFactura)" :key="serie.id" :value="serie">{{ serie.nombre }}</option>
                                            </select>
                                            <input style="width: 100px;" class="form-control" type="text" v-model="numeroPDFFactura">
                                            <span style="font-size: x-large;">/</span>
                                            <select style="width: 100px;" class="form-control" v-model="añoPDFFactura" id="">
                                                <option v-for="año in añosPDFFacturas" :key="año" :value="año">{{ año }}</option>
                                            </select>
                                        </div>
                                        <input style="width: 200px;" v-if="destinatarioPDFFactura!=''" class="form-control" type="date" v-model="fechaPDFFactura">
                                    </td>
                                    <td style="text-align: center;" v-if="!facturaPDFGenerada">
                                        <button class="btn btn-sm btn-primary" @click="generarFacturaPDF">Generar una factura</button>
                                    </td>
                                    <td style="display: flex; align-items: center;" v-else>
                                        <p style="margin: 0;">Ver factura</p><button class="btn" @click="descargarFacturaPDFCreada()" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Albaranes seleccionados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="totales in Object.entries(totalesPDFFactura)" :key="totales[0]">
                                    <td style="display: grid; grid-template-columns: 1fr 5fr">
                                        <div style="text-align: center;">
                                        <p>Total({{ totales[0] }}%)</p>
                                        </div>
                                        <div>
                                            <p>{{ totales[1].total }}€</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>            
            </div>
        </div>    
        <div class="card" v-if="albaranesCSVVisibles">
            <div class="card-header p-1 pt-1">
                <ul class="nav nav-pills" ref="tabHeaders">
                    <li  class="nav-item">
                        <a class="nav-link active" href="#facturacsv" data-toggle="tab">
                            Crear factura
                        </a>
                    </li>
                    <li v-if="variasfacturasCSV" class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#facturascsv">
                            Crear una factura por albarán
                        </a>
                    </li>
                </ul>
            </div>    
            <div class="card-body">
                <div class="tab-content" ref="tabContents">
                    <div class="tab-pane active container-grid" id="facturacsv">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Destinatario</th>
                                    <th>Empresa emisora</th>
                                    <th>Forma pago</th>
                                    <th>I.V.A.</th>
                                    <th>Nº Factura</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select v-model="destinatarioCSVFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cliente</option>
                                            <option v-for="comp in Object.entries(destinatariosCSVFactura)" :key="comp[0]" :value="comp[0]">{{ comp[1].nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="empresaCSVFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione empresa</option>
                                            <option v-for="empr in empresasCSVFactura" :key="empr.id" :value="empr.id">{{ empr.nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="formapagoCSVFactura" class="form-control" name="" id="">
                                            <option v-for="formas in formaspagoCSVFactura" :key="formas">{{ formas }}</option>
                                        </select>
                                        <select v-model="cuentaCSVFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cuenta</option>
                                            <option v-for="cuenta in cuentasCSVFactura" :value="cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                        </select>
                                    </td>
                                    <td style="display: flex;">
                                        <select class="form-control" v-model="ivaCSVFactura" id="">
                                            <option v-for="iva in ivasCSVFactura" :key="iva" :value="iva">{{ iva }}%</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="row" v-if="destinatarioCSVFactura!=''">
                                            <select style="width: 200px;" class="form-control" v-model="serieCSVFactura" id="">
                                                <option v-for="serie in Object.values(seriesCSVFactura)" :key="serie.id" :value="serie">{{ serie.nombre }}</option>
                                            </select>
                                            <input style="width: 100px;" class="form-control" type="text" v-model="numeroCSVFactura">
                                            <span style="font-size: x-large;">/</span>
                                            <select style="width: 100px;" class="form-control" v-model="añoCSVFactura" id="">
                                                <option v-for="año in añosCSVFacturas" :key="año" :value="año">{{ año }}</option>
                                            </select>
                                        </div>
                                        <input style="width: 200px;" v-if="destinatarioCSVFactura!=''" class="form-control" type="date" v-model="fechaCSVFactura">
                                    </td>
                                    <td style="text-align: center;" v-if="!facturaCSVGenerada">
                                        <button class="btn btn-sm btn-primary" @click="generarFacturaCSV">Generar una factura</button>
                                    </td>
                                    <td style="display: flex; align-items: center;" v-else>
                                        <p style="margin: 0;">Ver factura</p><button class="btn" @click="descargarFacturaCSVCreada()" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Albaranes seleccionados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="totales in Object.entries(totalesCSVFactura)" :key="totales[0]">
                                    <td style="display: grid; grid-template-columns: 1fr 5fr">
                                        <div style="text-align: center;">
                                        <p>Total({{ totales[0] }}%)</p>
                                        </div>
                                        <div>
                                            <p>{{ totales[1].total }}€</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane container-grid" id="facturascsv">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Forma pago</th>
                                    <th>Nº Factura</th>
                                    <th v-if="!facturasCSVGenerada"></th>
                                    <th v-else></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select v-model="formapagoCSVFactura" class="form-control" name="" id="">
                                            <option v-for="formas in formaspagoCSVFactura" :key="formas">{{ formas }}</option>
                                        </select>
                                        <select v-model="cuentaCSVFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cuenta</option>
                                            <option v-for="cuenta in cuentasCSVFactura" :value="cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                        </select>
                                    </td>
                                    <td v-if="serieCSVFactura2" style="display: flex; gap: 15px">
                                        <span>Serie:</span>
                                        <span style="font-weight: bolder">{{ serieCSVFactura2.nombre }}</span>
                                        <span>Nº Actual:</span>
                                        <span style="font-weight: bolder">{{ serieCSVFactura2.numero }}</span>
                                        <span>Año fiscal:</span>
                                        <select style="width: 100px;" class="form-control form-control-sm" v-model="añoCSVFiscal" id="">
                                            <option v-for="año in añosCSVFiscal" :key="año" :value="año">{{ año }}</option>
                                        </select>
                                    </td>
                                    <td v-else>
    
                                    </td>
                                    <td style="text-align: center;" v-if="!facturasCSVGenerada">
                                        <button class="btn btn-sm btn-primary" @click="generarFacturasCSV">Generar una factura por albarán</button>
                                    </td>
                                    <td style="display: flex; align-items: center;" v-else>
                                        <p>Facturas generadas!</p>
                                    </td>                              
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Albaranes seleccionados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="totales in Object.entries(totalesCSVFactura)" :key="totales[0]">
                                    <td style="display: grid; grid-template-columns: 1fr 5fr">
                                        <div style="text-align: center;">
                                        <p>Total({{ totales[0] }}%)</p>
                                        </div>
                                        <div>
                                            <p>{{ totales[1].total }}€</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>            
            </div>
        </div>
        <div class="card" v-if="albaranesCSVLDVisibles">
            <div class="card-header p-1 pt-1">
                <ul class="nav nav-pills" ref="tabHeaders">
                    <li  class="nav-item">
                        <a class="nav-link active" href="#facturacsvld" data-toggle="tab">
                            Crear factura
                        </a>
                    </li>
                    <li v-if="variasfacturasCSVLD" class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#facturascsvld">
                            Crear una factura por albarán
                        </a>
                    </li>
                </ul>
            </div>    
            <div class="card-body">
                <div class="tab-content" ref="tabContents">
                    <div class="tab-pane active container-grid" id="facturacsvld">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Destinatario</th>
                                    <th>Empresa emisora</th>
                                    <th>Forma pago</th>
                                    <th>I.V.A.</th>
                                    <th>Nº Factura</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select v-model="destinatarioCSVLDFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cliente</option>
                                            <option v-for="comp in Object.entries(destinatariosCSVLDFactura)" :key="comp[0]" :value="comp[0]">{{ comp[1].nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="empresaCSVLDFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione empresa</option>
                                            <option v-for="empr in empresasCSVLDFactura" :key="empr.id" :value="empr.id">{{ empr.nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select v-model="formapagoCSVLDFactura" class="form-control" name="" id="">
                                            <option v-for="formas in formaspagoCSVLDFactura" :key="formas">{{ formas }}</option>
                                        </select>
                                        <select v-model="cuentaCSVLDFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cuenta</option>
                                            <option v-for="cuenta in cuentasCSVLDFactura" :value="cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                        </select>
                                    </td>
                                    <td style="display: flex;">
                                        <select class="form-control" v-model="ivaCSVLDFactura" id="">
                                            <option v-for="iva in ivasCSVLDFactura" :key="iva" :value="iva">{{ iva }}%</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="row" v-if="destinatarioCSVLDFactura!=''">
                                            <select style="width: 200px;" class="form-control" v-model="serieCSVFactura" id="">
                                                <option v-for="serie in Object.values(seriesCSVLDFactura)" :key="serie.id" :value="serie">{{ serie.nombre }}</option>
                                            </select>
                                            <input style="width: 100px;" class="form-control" type="text" v-model="numeroCSVLDFactura">
                                            <span style="font-size: x-large;">/</span>
                                            <select style="width: 100px;" class="form-control" v-model="añoCSVLDFactura" id="">
                                                <option v-for="año in añosCSVLDFacturas" :key="año" :value="año">{{ año }}</option>
                                            </select>
                                        </div>
                                        <input style="width: 200px;" v-if="destinatarioCSVLDFactura!=''" class="form-control" type="date" v-model="fechaCSVLDFactura">
                                    </td>
                                    <td style="text-align: center;" v-if="!facturaCSVLDGenerada">
                                        <button class="btn btn-sm btn-primary" @click="generarFacturaCSVLD">Generar una factura</button>
                                    </td>
                                    <td style="display: flex; align-items: center;" v-else>
                                        <p style="margin: 0;">Ver factura</p><button class="btn" @click="descargarFacturaCSVLDCreada()" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Albaranes seleccionados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="totales in Object.entries(totalesCSVLDFactura)" :key="totales[0]">
                                    <td style="display: grid; grid-template-columns: 1fr 5fr">
                                        <div style="text-align: center;">
                                        <p>Total({{ totales[0] }}%)</p>
                                        </div>
                                        <div>
                                            <p>{{ totales[1].total }}€</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane container-grid" id="facturascsvld">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Forma pago</th>
                                    <th>Nº Factura</th>
                                    <th v-if="!facturasCSVLDGenerada"></th>
                                    <th v-else></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select v-model="formapagoCSVLDFactura" class="form-control" name="" id="">
                                            <option v-for="formas in formaspagoCSVLDFactura" :key="formas">{{ formas }}</option>
                                        </select>
                                        <select v-model="cuentaCSVLDFactura" class="form-control" name="" id="">
                                            <option value="">Seleccione cuenta</option>
                                            <option v-for="cuenta in cuentasCSVLDFactura" :value="cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                        </select>
                                    </td>
                                    <td v-if="serieCSVLDFactura2" style="display: flex; gap: 15px;">
                                        <span>Serie:</span>
                                        <span style="font-weight: bolder">{{ serieCSVLDFactura2.nombre }}</span>
                                        <span>Nº Actual:</span>
                                        <span style="font-weight: bolder">{{ serieCSVLDFactura2.numero }}</span>
                                        <span>Año fiscal:</span>
                                        <select style="width: 100px;" class="form-control form-control-sm" v-model="añoCSVLDFiscal" id="">
                                            <option v-for="año in añosCSVLDFiscal" :key="año" :value="año">{{ año }}</option>
                                        </select>
                                    </td>
                                    <td v-else>
    
                                    </td>
                                    <td style="text-align: center;" v-if="!facturasCSVLDGenerada">
                                        <button class="btn btn-sm btn-primary" @click="generarFacturasCSVLD">Generar una factura por albarán</button>
                                    </td>
                                    <td style="display: flex; align-items: center;" v-else>
                                        <p>Facturas generadas!</p>
                                    </td>                              
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Albaranes seleccionados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="totales in Object.entries(totalesCSVFactura)" :key="totales[0]">
                                    <td style="display: grid; grid-template-columns: 1fr 5fr">
                                        <div style="text-align: center;">
                                        <p>Total({{ totales[0] }}%)</p>
                                        </div>
                                        <div>
                                            <p>{{ totales[1].total }}€</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>            
            </div>
        </div>
        <div class="card" v-if="albaranesVisibles">
            <div class="card-header">
                <p class="card-title">Crear factura</p>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Destinatario</th>
                            <th>Empresa emisora</th>
                            <th>Forma pago</th>
                            <th>I.V.A.</th>
                            <th>Nº Factura</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select v-model="destinatarioFactura" class="form-control" name="" id="">
                                    <option value="">Seleccione cliente</option>
                                    <option v-for="comp in Object.entries(destinatariosFactura)" :key="comp[0]" :value="comp[0]">{{ comp[1].nombre }}</option>
                                </select>
                            </td>
                            <td>
                                <select v-model="empresaFactura" class="form-control" name="" id="">
                                    <option value="">Seleccione empresa</option>
                                    <option v-for="empr in empresasFactura" :key="empr.id" :value="empr.id">{{ empr.nombre }}</option>
                                </select>
                            </td>
                            <td>
                                <select v-model="formapagoFactura" class="form-control" name="" id="">
                                    <option v-for="formas in formaspagoFactura" :key="formas">{{ formas }}</option>
                                </select>
                                <select v-model="cuentaFactura" class="form-control" name="" id="">
                                    <option value="">Seleccione cuenta</option>
                                    <option v-for="cuenta in cuentasFactura" :value="cuenta.id_cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                                </select>
                            </td>
                            <td style="display: flex;">
                                <select class="form-control" v-model="ivaFactura" id="">
                                    <option v-for="iva in ivasFactura" :key="iva" :value="iva">{{ iva }}%</option>
                                </select>
                            </td>
                            <td>
                                <div class="row" v-if="destinatarioFactura!=''">
                                    <select style="width: 200px;" class="form-control" v-model="serieFactura" id="">
                                        <option v-for="serie in Object.values(seriesFactura)" :key="serie.id" :value="serie">{{ serie.nombre }}</option>
                                    </select>
                                    <input style="width: 100px;" class="form-control" type="text" v-model="numeroFactura">
                                    <span style="font-size: x-large;">/</span>
                                    <select style="width: 100px;" class="form-control" v-model="añoFactura" id="">
                                        <option v-for="año in añosFacturas" :key="año" :value="año">{{ año }}</option>
                                    </select>
                                </div>
                                <input style="width: 200px;" v-if="destinatarioFactura!=''" class="form-control" type="date" v-model="fechaFactura">
                            </td>
                            <td style="text-align: center;" v-if="!facturaGenerada">
                                <button class="btn btn-sm btn-primary" @click="generarFactura">Generar una factura</button>
                            </td>
                            <td style="display: flex; align-items: center;" v-else>
                                <p style="margin: 0;">Ver factura</p><button class="btn" @click="descargarFacturaCreada()" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="text-align: center;">Albaranes seleccionados</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="totales in Object.entries(totalesFactura)" :key="totales[0]">
                            <td style="display: grid; grid-template-columns: 1fr 5fr">
                                <div style="text-align: center;">
                                   <p>Total({{ totales[0] }}%)</p>
                                </div>
                                <div>
                                    <p>{{ totales[1].total }}€</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>    
    </div>
</template>
<script>
import $ from 'jquery';
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../services/PwgsApi';
import FileUpload from 'primevue/fileupload';
export default{    
    data(){
        return {
            spinner:false,
            conceptoFacturaAbonar:'',
            ivaAbono:'',
            impuestos:[],
            importeFacturaAbonar:'',
            numeroFacturaAbonar:'',
            companias:null,
            companiasSeleccionadas:[],
            empresas:null,
            empresaselec:'',
            fecha_inicio:'',
            fecha_fin:'',
            facturadoselec:'',
            busquedaNombre:'',
            numeroAlbaran:'',

            importe_total:0,
            
            importe_base:0,
            importe_base_csvld:0,
            importe_base_pdf:0,
            importe_base_csv:0,

            albaranes:[],
            albaranesCSV:[],
            albaranesCSVLD:[],
            albaranesPDF:[],

            albaranesVisibles:false,
            albaranesCSVVisibles:false,
            albaranesCSVLDVisibles:false,
            albaranesPDFVisibles:false,

            numerosExpedientes:'',

            incluirelacionados:false,
            incluirelacionadospdf:false,

            mostrarCSV:false,
            mostrarCSVLD:false,
            mostrarPDF:false,

            series:[],

            serieCSVseleccionada:'',
            serieCSVLDseleccionada:'', 

            incluiravisoscsv:false,
            incluiravisoscsvld:false,

            albaranes_csv:[],
            albaranes_csvld:[],

            archivoSubidocsv:'',
            archivoSubidocsvld:'',
            archivoSubidopdf:'',

            checkTodas:false,
            checkTodasCSV:false,
            checkTodasCSVLD:false,
            checkTodasPDF:false,

            facturaaeliminar:null,
            dialogeliminar : false,
            motivoEliminar:'',

            dialogabonar:false,
            albaranaabonar:null,

            ivaFactura:'',
            ivaCSVFactura:'',
            ivaCSVLDFactura:'',
            ivaPDFFactura:'',

            ivasFactura:[],
            ivasCSVFactura:[],
            ivasCSVLDFactura:[],
            ivasPDFVactura:[],

            destinatarioFactura:'',
            destinatarioCSVFactura:'',
            destinatarioCSVLDFactura:'',
            destinatarioPDFFactura:'',

            destinatariosFactura:[],
            destinatariosCSVFactura:[],
            destinatariosCSVLDFactura:[],
            destinatariosPDFFactura:[],   

            fechaFactura:'',
            fechaCSVFactura:'',
            fechaCSVLDFactura:'',
            fechaPDFFactura:'',

            numeroFactura:'',
            numeroCSVFactura:'',
            numeroCSVLDFactura:'',
            numeroPDFFactura:'',    

            añosFacturas:[],
            añosCSVFacturas:[],
            añosCSVLDFacturas:[],
            añosPDFFacturas:[],

            añosCSVFiscal:[],
            añosCSVLDFiscal:[],

            añoFactura:'',
            añoCSVFactura:'',
            añoCSVLDFactura:'',
            añoPDFFactura:'',

            añoCSVFiscal:'',
            añoCSVLDFiscal:'',

            formaspagoFactura:[],
            formaspagoCSVFactura:[],
            formaspagoCSVLDFactura:[],  
            fermaspagoPDFFactura:[],     

            formapagoFactura:'',
            formapagoCSVFactura:'',
            formapagoCSVLDFactura:'',
            formapagoPDFFactura:'',

            seriesFactura:[],
            seriesCSVFactura:[],
            seriesCSVLDFactura:[],  
            seriesPDFFactura:[],     

            serieFactura:'',
            serieCSVFactura:'',
            serieCSVLDFactura:'',   
            seriePDFFactura:'',

            serieCSVFactura2:'',
            serieCSVLDFactura2:'',

            cuentasFactura:[],
            cuentasCSVFactura:[],
            cuentasCSVLDFactura:[],
            cuentasPDFFactura:[],

            cuentaFactura:'',
            cuentaCSVFactura:'',
            cuentaCSVLDFactura:'',
            cuentaPDFFactura:'',

            empresaFactura:'',
            empresaCSVFactura:'',
            empresaCSVLDFactura:'',
            empresaPDFFactura:'',
            
            empresasFactura:[],
            empresasCSVFactura:[],
            empresasCSVLDFactura:[],
            empresasPDFFactura:[],
            
            año_actual:'',
            totalesFactura:'',
            totalesCSVFactura:'',
            totalesCSVLDFactura:'',
            totalesPDFFactura:'',
            
            albaranesMarcados:[],
            albaranesMarcadosCSV:[],
            albaranesMarcadosCSVLD:[],
            albaranesMarcadosPDF:[],

            dia_actual:'',
            
            facturaGenerada:false,
            facturaCSVGenerada:false,
            facturaCSVLDGenerada:false,
            facturaPDFGenerada:false,
            facturasCSVGenerada:false,
            facturasCSVLDGenerada:false,

            urlFacturaCreada:'',
            urlFacturaCSVCreada:'',
            urlFacturaCSVLDCreada:'',
            urlFacturaPDFCreada:'',

            erroresCSV:[],
            erroresCSVLD:[],
            erroresPDF:[],

            variasfacturasCSV:false,
            variasfacturasCSVLD:false,
            mostrarrelacionados:false,
            mostraravisos:false,
        }
    },
    components:{
        MultiSelect,
        FileUpload,
    },
    computed: {     
    },
    methods:{
        async abonarFactura(){
            try{
                const api = new PwgsApi();
                await api.post('facturas/'+this.albaranaabonar.id_factura+'/abonar', {importe: this.importeFacturaAbonar, concepto: this.conceptoFacturaAbonar, 
                    iva: this.ivaAbono, numero:this.numeroFacturaAbonar});
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Factura abonada correctamente', life: 2000 });
                this.cerrarFormAbonar();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        botonabonar(albaran){
            console.log('albar', albaran);
            if(albaran.abonos.parciales=='1'){
                this.albaranaabonar = albaran;
                albaran.importe_base = albaran.importe_base.replace(',', '.');            
                this.importeFacturaAbonar = parseFloat(albaran.importe_base);
                this.numeroFacturaAbonar = parseInt(albaran.siguiente_numero);
                this.conceptoFacturaAbonar='';
                this.ivaAbono = albaran.iva_defecto;
                this.dialogabonar = true;
            }else{
                this.confirmar();
            }
        },
        confirmar(){
            this.$confirm.require({
                    message: '¿Seguro que quieres abonar la factura?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined',
                    rejectLabel: 'Cancelar',
                    acceptLabel: 'Aceptar',
                    accept: () => {
                    this.pagarfactura()
                    },
                    reject: () => {
                        return;
                    }
                });
            
        },
        async pagarfactura() {
            //PUT  modulos/pwgsapi/index.php/facturas/:id_servicio/enviar-pago-tpv
            const api = new PwgsApi();
            try{
            await api.post('facturas/'+this.albaranaabonar.id_factura+'/abonar');
            this.$toast.add({ severity:'success', summary: 'Abonado', detail: 'Factura abonada', life: 2000 });            
            }catch(e){
            this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async generarFactura(){
            const api = new PwgsApi();
            if(this.albaranesMarcados.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.destinatarioFactura==''){
                alert('Debe seleccionar alguna compañía.')
                return;
            }
            if(this.empresaFactura==''){
                alert('Debe seleccionar alguna empresa.')
                return;
            }
            if(this.formapagoFactura == 'Transferencia Bancaria' && this.cuentaFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {ids_albaranes: this.albaranesMarcados, id_compania:this.destinatarioFactura, id_empresa:this.empresaFactura,
                forma_pago:this.formapagoFactura,tipo_iva:this.ivaFactura, id_serie:this.serieFactura.id, numero:this.numeroFactura,
                yead: this.añoFactura, fecha_factura:this.fechaFactura}
            if(this.formapagoFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaFactura.iban_cuenta +'-'+this.cuentaFactura.iban2_cuenta +'-'+ this.cuentaFactura.entidad_cuenta +'-'+this.cuentaFactura.oficina_cuenta +'-'+this.cuentaFactura.control_cuenta +'-'+ this.cuentaFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            try{
                const resp = await api.post('facturacion-albaranes/facturar', body);
                this.facturaGenerada = true;
                this.urlFacturaCreada = resp.url_factura_pdf;
                this.actualizarLista();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async generarFacturaCSV(){
            const api = new PwgsApi();
            if(this.albaranesMarcadosCSV.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.destinatarioCSVFactura==''){
                alert('Debe seleccionar alguna compañía.')
                return;
            }
            if(this.empresaCSVFactura==''){
                alert('Debe seleccionar alguna empresa.')
                return;
            }
            if(this.formapagoCSVFactura == 'Transferencia Bancaria' && this.cuentaCSVFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {ids_albaranes: this.albaranesMarcadosCSV, id_compania:this.destinatarioCSVFactura, id_empresa:this.empresaCSVFactura,
                forma_pago:this.formapagoCSVFactura,tipo_iva:this.ivaCSVFactura, id_serie:this.serieCSVFactura.id, numero:this.numeroCSVFactura,
                year: this.añoCSVFactura, fecha_factura:this.fechaCSVFactura}
            if(this.formapagoCSVFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaCSVFactura.iban_cuenta +'-'+this.cuentaCSVFactura.iban2_cuenta +'-'+ this.cuentaCSVFactura.entidad_cuenta +'-'+this.cuentaCSVFactura.oficina_cuenta +'-'+this.cuentaCSVFactura.control_cuenta +'-'+ this.cuentaCSVFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            try{
                const resp = await api.post('facturacion-albaranes/facturar', body);
                this.facturaCSVGenerada = true;
                this.urlFacturaCSVCreada = resp.url_factura_pdf;
                this.actualizarListaCSV();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async generarFacturasCSV(){
            const api = new PwgsApi();
            if(this.albaranesMarcadosCSV.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.formapagoCSVFactura == 'Transferencia Bancaria' && this.cuentaCSVFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {forma_pago:this.formapagoCSVFactura, id_serie:this.serieCSVFactura2.id, 
                year: this.añoCSVFiscal}
            if(this.formapagoCSVFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaCSVFactura.iban_cuenta +'-'+this.cuentaCSVFactura.iban2_cuenta +'-'+ this.cuentaCSVFactura.entidad_cuenta +'-'+this.cuentaCSVFactura.oficina_cuenta +'-'+this.cuentaCSVFactura.control_cuenta +'-'+ this.cuentaCSVFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            var albaranes = {};
            for(var alb of this.albaranesMarcadosCSV){
                for(var albcsv of Object.entries(this.albaranes_csv)){
                    if(albcsv[0] == alb){
                        albaranes[alb] = albcsv[1];
                    }
                }
            }
            body.albaranes_csv = albaranes;
            try{
                const resp = await api.post('facturacion-albaranes/facturar-por-albaran', body);
                this.facturasCSVGenerada = true;
                this.urlFacturaCSVCreada = resp.url_factura_pdf;
                this.actualizarListaCSV();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async generarFacturaCSVLD(){
            const api = new PwgsApi();
            if(this.albaranesMarcadosCSVLD.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.destinatarioCSVLDFactura==''){
                alert('Debe seleccionar alguna compañía.')
                return;
            }
            if(this.empresaCSVLDFactura==''){
                alert('Debe seleccionar alguna empresa.')
                return;
            }
            if(this.formapagoCSVLDFactura == 'Transferencia Bancaria' && this.cuentaCSVLDFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {ids_albaranes: this.albaranesMarcadosCSVLD, id_compania:this.destinatarioCSVLDFactura, id_empresa:this.empresaCSVLDFactura,
                forma_pago:this.formapagoCSVLDFactura,tipo_iva:this.ivaCSVLDFactura, id_serie:this.serieCSVLDFactura.id, numero:this.numeroCSVLDFactura,
                year: this.añoCSVLDFactura, fecha_factura:this.fechaCSVLDFactura}
            if(this.formapagoCSVLDFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaCSVLDFactura.iban_cuenta +'-'+this.cuentaCSVLDFactura.iban2_cuenta +'-'+ this.cuentaCSVLDFactura.entidad_cuenta +'-'+this.cuentaCSVLDFactura.oficina_cuenta +'-'+this.cuentaCSVLDFactura.control_cuenta +'-'+ this.cuentaCSVLDFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            try{
                const resp = await api.post('facturacion-albaranes/facturar', body);
                this.facturaCSVLDGenerada = true;
                this.urlFacturaCSVLDCreada = resp.url_factura_pdf;
                this.actualizarListaCSVLD();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async generarFacturasCSVLD(){
            const api = new PwgsApi();
            if(this.albaranesMarcadosCSVLD.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.formapagoCSVLDFactura == 'Transferencia Bancaria' && this.cuentaCSVLDFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {forma_pago:this.formapagoCSVLDFactura, id_serie:this.serieCSVLDFactura2.id, 
                year: this.añoCSVLDFiscal}
            if(this.formapagoCSVLDFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaCSVLDFactura.iban_cuenta +'-'+this.cuentaCSVLDFactura.iban2_cuenta +'-'+ this.cuentaCSVLDFactura.entidad_cuenta +'-'+this.cuentaCSVLDFactura.oficina_cuenta +'-'+this.cuentaCSVLDFactura.control_cuenta +'-'+ this.cuentaCSVLDFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            var albaranes = {};
            for(var alb of this.albaranesMarcadosCSVLD){
                for(var albcsv of Object.entries(this.albaranes_csvld)){
                    if(albcsv[0] == alb){
                        albaranes[alb] = albcsv[1];
                    }
                }
            }
            body.albaranes_csv = albaranes;
            try{
                const resp = await api.post('facturacion-albaranes/facturar-por-albaran', body);
                this.facturasCSVLDGenerada = true;
                this.urlFacturaCSVLDCreada = resp.url_factura_pdf;
                this.actualizarListaCSVLD();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async generarFacturaPDF(){
            const api = new PwgsApi();
            if(this.albaranesMarcadosPDF.length < 1){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.destinatarioPDFFactura==''){
                alert('Debe seleccionar alguna compañía.')
                return;
            }
            if(this.empresaPDFFactura==''){
                alert('Debe seleccionar alguna empresa.')
                return;
            }
            if(this.formapagoPDFFactura == 'Transferencia Bancaria' && this.cuentaPDFFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {ids_albaranes: this.albaranesMarcadosPDF, id_compania:this.destinatarioPDFFactura, id_empresa:this.empresaPDFFactura,
                forma_pago:this.formapagoPDFFactura,tipo_iva:this.ivaPDFFactura, id_serie:this.seriePDFFactura.id, numero:this.numeroPDFFactura,
                yead: this.añoPDFFactura, fecha_factura:this.fechaPDFFactura}
            if(this.formapagoPDFFactura == 'Transferencia Bancaria'){
                var cuenta = this.cuentaPDFFactura.iban_cuenta +'-'+this.cuentaPDFFactura.iban2_cuenta +'-'+ this.cuentaPDFFactura.entidad_cuenta +'-'+this.cuentaPDFFactura.oficina_cuenta +'-'+this.cuentaPDFFactura.control_cuenta +'-'+ this.cuentaPDFFactura.numero_cuenta;
                body.cuenta_bancaria=cuenta;
            }
            try{
                const resp = await api.post('facturacion-albaranes/facturar', body);
                this.facturaPDFGenerada = true;
                this.urlFacturaPDFCreada = resp.url_factura_pdf;
                this.actualizarListaPDF();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },

        actualizarLista(){
            console.log('actualizarLista');
            var aux = this.albaranes;
            this.albaranes = [];
            setTimeout(() => {
                this.albaranes = aux;
            }, 1000);
           
        },
        actualizarListaCSV(){
            console.log('actualizarLista');
            var aux = this.albaranesCSV;
            this.albaranesCSV = [];
            setTimeout(() => {
                this.albaranesCSV = aux;
            }, 1000);
           
        },
        actualizarListaCSVLD(){
            console.log('actualizarLista');
            var aux = this.albaranesCSVLD;
            this.albaranesCSVLD = [];
            setTimeout(() => {
                this.albaranesCSVLD = aux;
            }, 1000);
           
        },
        actualizarListaPDF(){
            console.log('actualizarLista');
            var aux = this.albaranesPDF;
            this.albaranesPDF = [];
            setTimeout(() => {
                this.albaranesPDF = aux;
            }, 1000);
           
        },
        marcado(albaran){
            console.log('entrachange', albaran);
            if(albaran.marcada){
                this.albaranesMarcados.push(albaran.id_albaran);
                console.log(this.albaranesMarcados);
                this.destinatarioFactura = '';
                this.obtenerDatosFactura();
            }else{
                let index = this.albaranesMarcados.indexOf(albaran.id_albaran);
                if (index > -1) {
                    this.albaranesMarcados.splice(index, 1);
                    console.log(this.albaranesMarcados);
                }
                this.destinatarioFactura = '';
                this.obtenerDatosFactura();
            }
        },
        marcadoCSV(albaran){
            console.log('entrachange', albaran);
            if(albaran.marcada){
                this.albaranesMarcadosCSV.push(albaran.id_albaran);
                console.log(this.albaranesMarcadosCSV);
                this.destinatarioCSVFactura = '';
                this.obtenerDatosFacturaCSV();
            }else{
                let index = this.albaranesMarcadosCSV.indexOf(albaran.id_albaran);
                if (index > -1) {
                    this.albaranesMarcadosCSV.splice(index, 1);
                    console.log(this.albaranesMarcadosCSV);
                }
                this.destinatarioCSVFactura = '';
                this.obtenerDatosFacturaCSV();
            }
        },
        marcadoCSVLD(albaran){
            console.log('entrachange', albaran);
            if(albaran.marcada){
                this.albaranesMarcadosCSVLD.push(albaran.id_albaran);
                console.log(this.albaranesMarcadosCSVLD);
                this.destinatarioCSVLDFactura = '';
                this.obtenerDatosFacturaCSVLD();
            }else{
                let index = this.albaranesMarcadosCSVLD.indexOf(albaran.id_albaran);
                if (index > -1) {
                    this.albaranesMarcadosCSVLD.splice(index, 1);
                    console.log(this.albaranesMarcadosCSVLD);
                }
                this.destinatarioCSVLDFactura = '';
                this.obtenerDatosFacturaCSVLD();
            }
        },
        marcadoPDF(albaran){
            console.log('entrachange', albaran);
            if(albaran.marcada){
                this.albaranesMarcadosPDF.push(albaran.id_albaran);
                console.log(this.albaranesMarcadosPDF);
                this.destinatarioPDFFactura = '';
                this.obtenerDatosFacturaPDF();
            }else{
                let index = this.albaranesMarcadosPDF.indexOf(albaran.id_albaran);
                if (index > -1) {
                    this.albaranesMarcadosPDF.splice(index, 1);
                    console.log(this.albaranesMarcadosPDF);
                }
                this.destinatarioPDFFactura = '';
                this.obtenerDatosFacturaPDF();
            }
        },
        irServicio(albaran){
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:albaran.id_servicio
                }
            });
        },
        confirmarEliminar(albaran) {
            this.facturaaeliminar = albaran;
            this.dialogeliminar=true;
        },
        cerrarForm(){
            this.dialogeliminar = false;
            this.motivoEliminar='';
            this.facturaaeliminar=null;
        },
        cerrarFormAbonar(){
            this.albaranaabonar = null;
            this.dialogabonar = false;
        },
        async eliminarAlbaran(){
            if(this.motivoEliminar==''){
                alert('Debe indicar un motivo.')
                return;
            }
            const api = new PwgsApi();
            const params = {motivo:this.motivoEliminar};
            await api.deletebody('facturas/' + this.facturaaeliminar.id_factura, params);
            this.facturaaeliminar.marcada = false;
            this.facturaaeliminar.factura = null;
            var albaranEncontrado = this.albaranes.filter(albaran => albaran == this.facturaaeliminar);
            albaranEncontrado.marcada = false;
            albaranEncontrado.factura = null;
            this.cerrarForm();
        },
        descargarFactura(albaran){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+albaran.url_factura_pdf;
            }else{
                url  = "https://"+urlglobal+'/'+albaran.url_factura_pdf;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        descargarFacturaCreada(){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+this.urlFacturaCreada;
            }else{
                url  = "https://"+urlglobal+'/'+this.urlFacturaCreada;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        descargarFacturaCSVCreada(){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+this.urlFacturaCSVCreada;
            }else{
                url  = "https://"+urlglobal+'/'+this.urlFacturaCSVCreada;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        descargarFacturaCSVLDCreada(){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+this.urlFacturaCSVLDCreada;
            }else{
                url  = "https://"+urlglobal+'/'+this.urlFacturaCSVLDCreada;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        descargarFacturaPDFCreada(){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+this.urlFacturaPDFCreada;
            }else{
                url  = "https://"+urlglobal+'/'+this.urlFacturaPDFCreada;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        marcarTodas(){
            if(!this.checkTodas){
                for(let albaran of this.albaranes){
                    console.log('entrafor', );
                    if(!albaran.factura){
                        console.log('entraalbaran', albaran);
                        albaran.marcada = false;
                        this.albaranesMarcados = [];
                    }
                }
            }else{
                for(let albaran of this.albaranes){
                    if(!albaran.factura){
                        albaran.marcada = true;
                        this.albaranesMarcados.push(albaran.id_albaran);
                    }
                }
            }
            this.obtenerDatosFactura();
        },
        marcarTodasCSV(){
            if(!this.checkTodasCSV){
                for(let albaran of this.albaranesCSV){
                    console.log('entrafor', );
                    if(!albaran.factura){
                        console.log('entraalbaran', albaran);
                        albaran.marcada = false;
                        this.albaranesMarcadosCSV = [];
                    }
                }
            }else{
                for(let albaran of this.albaranesCSV){
                    if(!albaran.factura){
                        albaran.marcada = true;
                        this.albaranesMarcadosCSV.push(albaran.id_albaran);
                    }
                }
            }
            this.obtenerDatosFacturaCSV();
        },
        marcarTodasCSVLD(){
            if(!this.checkTodasCSVLD){
                for(let albaran of this.albaranesCSVLD){
                    console.log('entrafor', );
                    if(!albaran.factura){
                        console.log('entraalbaran', albaran);
                        albaran.marcada = false;
                        this.albaranesMarcadosCSVLD = [];
                    }
                }
            }else{
                for(let albaran of this.albaranesCSVLD){
                    if(!albaran.factura){
                        albaran.marcada = true;
                        this.albaranesMarcadosCSVLD.push(albaran.id_albaran);
                    }
                }
            }
            this.obtenerDatosFacturaCSVLD();
        },
        marcarTodasPDF(){
            if(!this.checkTodasPDF){
                for(let albaran of this.albaranesPDF){
                    console.log('entrafor', );
                    if(!albaran.factura){
                        console.log('entraalbaran', albaran);
                        albaran.marcada = false;
                        this.albaranesMarcadosPDF = [];
                    }
                }
            }else{
                for(let albaran of this.albaranesPDF){
                    if(!albaran.factura){
                        albaran.marcada = true;
                        this.albaranesMarcadosPDF.push(albaran.id_albaran);
                    }
                }
            }
            this.obtenerDatosFacturaPDF();
        },
        subirarchivo(event) {
            console.log('subirarc',event);
            this.archivoSubidocsv = event.files[0];
            console.log('subirarc2',this.archivoSubidocsv);
        },
        subirarchivold(event) {
            console.log('subirarc',event);
            this.archivoSubidocsvld = event.files[0];
            console.log('subirarc2',this.archivoSubidocsvld);
        },
        subirarchivoPdf(event) {
            console.log('subirarc',event);
            this.archivoSubidopdf = event.files[0];
            console.log('subirarc2',this.archivoSubidopdf);
        },
        async mostrarAlbaranesBusqueda(){
            this.spinner = true;
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = [];
                for(let compa of this.companiasSeleccionadas){
                    companias.push(compa.id);
                }
            }
            this.albaranes = [];
            const api = new PwgsApi();
            var body = {id_empresa: this.empresaselec, ids_companias:companias, fecha_inicio:this.fechaesp(this.fecha_inicio), fecha_fin:this.fechaesp(this.fecha_fin)};
            if(this.numeroAlbaran){
                body.numero = this.numeroAlbaran;
            }
            if(this.busquedaNombre){
                body.nombre = this.busquedaNombre;
            }
            if(this.facturadoselec){
                body.facturado = this.facturadoselec;
            }
            try{
                this.loading = true;
                const resp = await api.post('facturacion-albaranes/busqueda',body);
                for(let alb of Object.entries(resp)){
                    if(alb[0] == 'importe_totales'){
                        this.importe_total = alb[1];
                    }else if(alb[0] == 'importe_bases'){
                        this.importe_base = parseFloat(alb[1]).toFixed(2);
                    }else{
                        if(alb[1].factura){
                            alb[1].marcada = false;
                        }
                        this.albaranes.push(alb[1]);
                    }
                }
            }catch(e){
                console.error(e);
                this.spinner = false;
            }finally{
                console.log()
                this.albaranesMarcados = [];
                this.facturaGenerada = false;
                this.urlFacturaCreada = '';
                this.obtenerDatosFactura();
                this.albaranesCSVVisibles = false;
                this.albaranesPDFVisibles = false;
                this.albaranesCSVLDVisibles = false;
                this.albaranesVisibles = true;
                this.spinner = false;
            }

        },
        async obtenerDatosFactura(){
            const api = new PwgsApi();
            console.log('albasmarcas',this.albaranesMarcados);            
            if(this.albaranesMarcados.length > 0){
                if(this.destinatarioFactura!=''){
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcados,year:this.añoFactura,id_compania:this.destinatarioFactura}); 
                    this.formaspagoFactura = siguiente.formas_pago;
                    this.formapagoFactura = siguiente.forma_pago_defecto;
                    this.cuentasFactura = siguiente.cuentas_bancarias;
                    this.ivasFactura = siguiente.tipos_iva;
                    for(var iva of this.ivasFactura){
                        this.ivaFactura = iva;
                        break;
                    }
                    this.totalesFactura = siguiente.totales;
                    this.empresasFactura = siguiente.empresas;
                    this.destinatariosFactura = siguiente.companias;                    
                    for(var sers of Object.values(siguiente.series_compania_empresa)){
                        console.log('sers',sers);
                        this.seriesFactura = sers[0].series;
                    }
                    console.log('seriesfact',this.seriesFactura);
                    for(var serie of Object.values(this.seriesFactura)){
                        this.serieFactura = serie;
                        break;
                    }
                }else{
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcados,year:this.añoFactura}); 
                    this.formaspagoFactura = siguiente.formas_pago;
                    this.formapagoFactura = siguiente.forma_pago_defecto;
                    this.cuentasFactura = siguiente.cuentas_bancarias;
                    this.ivasFactura = siguiente.tipos_iva;
                    for(var iv of this.ivasFactura){
                        this.ivaFactura = iv;
                        break;
                    }
                    this.totalesFactura = siguiente.totales;
                    this.empresasFactura = siguiente.empresas;
                    this.destinatariosFactura = siguiente.companias;
                }
            }else{
                const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:[],year:this.añoFactura}); 
                this.formaspagoFactura = siguiente.formas_pago;
                this.formapagoFactura = siguiente.forma_pago_defecto;
                this.cuentasFactura = siguiente.cuentas_bancarias;
                this.ivasFactura = siguiente.tipos_iva;
                for(var ia of this.ivasFactura){
                        this.ivaFactura = ia;
                        break;
                    }
                this.totalesFactura = siguiente.totales;
                this.empresasFactura = [];
                this.empresaFactura = '';
                this.destinatariosFactura = [];
                this.destinatarioFactura = '';
            }
            this.fechaFactura = this.fechaing(this.dia_actual);
        },
        async obtenerDatosFacturaCSV(){
            const api = new PwgsApi();
            console.log('albasmarcas',this.albaranesMarcadosCSV);            
            if(this.albaranesMarcadosCSV.length > 0){
                if(this.destinatarioCSVFactura!=''){
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVseleccionada,ids_albaranes:this.albaranesMarcadosCSV,year:this.añoCSVFactura,id_compania:this.destinatarioCSVFactura}); 
                    this.formaspagoCSVFactura = siguiente.formas_pago;
                    this.formapagoCSVFactura = siguiente.forma_pago_defecto;
                    this.cuentasCSVFactura = siguiente.cuentas_bancarias;
                    this.ivasCSVFactura = siguiente.tipos_iva;
                    this.serieCSVFactura2 = siguiente.serie;
                    for(var iva of this.ivasCSVFactura){
                        this.ivaCSVFactura = iva;
                        break;
                    }
                    this.totalesCSVFactura = siguiente.totales;
                    this.empresasCSVFactura = siguiente.empresas;
                    this.destinatariosCSVFactura = siguiente.companias;                    
                    for(var sers of Object.values(siguiente.series_compania_empresa)){
                        console.log('sers',sers);
                        this.seriesCSVFactura = sers[0].series;
                    }
                    console.log('seriesfact',this.seriesCSVFactura);
                    for(var serie of Object.values(this.seriesCSVFactura)){
                        this.serieCSVFactura = serie;
                        break;
                    }
                }else{
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVseleccionada,ids_albaranes:this.albaranesMarcadosCSV,year:this.añoCSVFactura}); 
                    this.formaspagoCSVFactura = siguiente.formas_pago;
                    this.formapagoCSVFactura = siguiente.forma_pago_defecto;
                    this.cuentasCSVFactura = siguiente.cuentas_bancarias;
                    this.ivasCSVFactura = siguiente.tipos_iva;
                    this.serieCSVFactura2 = siguiente.serie;
                    for(var iv of this.ivasCSVFactura){
                        this.ivaCSVFactura = iv;
                        break;
                    }
                    this.totalesCSVFactura = siguiente.totales;
                    this.empresasCSVFactura = siguiente.empresas;
                    this.destinatariosCSVFactura = siguiente.companias;
                }
            }else{
                const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVseleccionada,ids_albaranes:[],year:this.añoCSVFactura}); 
                this.formaspagoCSVFactura = siguiente.formas_pago;
                this.formapagoCSVFactura = siguiente.forma_pago_defecto;
                this.cuentasCSVFactura = siguiente.cuentas_bancarias;
                this.ivasCSVFactura = siguiente.tipos_iva;
                this.serieCSVFactura2 = siguiente.serie;
                for(var ia of this.ivasCSVFactura){
                        this.ivaCSVFactura = ia;
                        break;
                    }
                this.totalesCSVFactura = siguiente.totales;
                this.empresasCSVFactura = [];
                this.empresaCSVFactura = '';
                this.destinatariosCSVFactura = [];
                this.destinatarioCSVFactura = '';
            }
            this.fechaCSVFactura = this.fechaing(this.dia_actual);
        },
        async obtenerDatosFacturaCSVLD(){
            const api = new PwgsApi();
            console.log('albasmarcas',this.albaranesMarcadosCSVLD);            
            if(this.albaranesMarcadosCSVLD.length > 0){
                if(this.destinatarioCSVLDFactura!=''){
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVLDseleccionada,ids_albaranes:this.albaranesMarcadosCSVLD,year:this.añoCSVLDFactura,id_compania:this.destinatarioCSVLDFactura}); 
                    this.formaspagoCSVLDFactura = siguiente.formas_pago;
                    this.formapagoCSVLDFactura = siguiente.forma_pago_defecto;
                    this.cuentasCSVLDFactura = siguiente.cuentas_bancarias;
                    this.ivasCSVLDFactura = siguiente.tipos_iva;
                    this.serieCSVLDFactura2 = siguiente.serie;
                    for(var iva of this.ivasCSVLDFactura){
                        this.ivaCSVLDFactura = iva;
                        break;
                    }
                    this.totalesCSVLDFactura = siguiente.totales;
                    this.empresasCSVLDFactura = siguiente.empresas;
                    this.destinatariosCSVLDFactura = siguiente.companias;                    
                    for(var sers of Object.values(siguiente.series_compania_empresa)){
                        console.log('sers',sers);
                        this.seriesCSVLDFactura = sers[0].series;
                    }
                    console.log('seriesfact',this.seriesCSVLDFactura);
                    for(var serie of Object.values(this.seriesCSVLDFactura)){
                        this.serieCSVLDFactura = serie;
                        break;
                    }
                }else{
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVLDseleccionada,ids_albaranes:this.albaranesMarcadosCSVLD,year:this.añoCSVLDFactura}); 
                    this.formaspagoCSVLDFactura = siguiente.formas_pago;
                    this.formapagoCSVLDFactura = siguiente.forma_pago_defecto;
                    this.cuentasCSVLDFactura = siguiente.cuentas_bancarias;
                    this.ivasCSVLDFactura = siguiente.tipos_iva;
                    this.serieCSVLDFactura2 = siguiente.serie;
                    for(var iv of this.ivasCSVLDFactura){
                        this.ivaCSVLDFactura = iv;
                        break;
                    }
                    this.totalesCSVLDFactura = siguiente.totales;
                    this.empresasCSVLDFactura = siguiente.empresas;
                    this.destinatariosCSVLDFactura = siguiente.companias;
                }
            }else{
                const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{id_serie:this.serieCSVLDseleccionada,ids_albaranes:[],year:this.añoCSVLDFactura}); 
                this.formaspagoCSVLDFactura = siguiente.formas_pago;
                this.formapagoCSVLDFactura = siguiente.forma_pago_defecto;
                this.cuentasCSVLDFactura = siguiente.cuentas_bancarias;
                this.ivasCSVLDFactura = siguiente.tipos_iva;
                this.serieCSVLDFactura2 = siguiente.serie;
                for(var ia of this.ivasCSVLDFactura){
                        this.ivaCSVLDFactura = ia;
                        break;
                    }
                this.totalesCSVLDFactura = siguiente.totales;
                this.empresasCSVLDFactura = [];
                this.empresaCSVLDFactura = '';
                this.destinatariosCSVLDFactura = [];
                this.destinatarioCSVLDFactura = '';
            }
            this.fechaCSVLDFactura = this.fechaing(this.dia_actual);
        },
        async obtenerDatosFacturaPDF(){
            const api = new PwgsApi();
            console.log('albasmarcas',this.albaranesMarcadosPDF);            
            if(this.albaranesMarcadosPDF.length > 0){
                if(this.destinatarioPDFFactura!=''){
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcadosPDF,year:this.añoPDFFactura,id_compania:this.destinatarioPDFFactura}); 
                    this.formaspagoPDFFactura = siguiente.formas_pago;
                    this.formapagoPDFFactura = siguiente.forma_pago_defecto;
                    this.cuentasPDFFactura = siguiente.cuentas_bancarias;
                    this.ivasPDFFactura = siguiente.tipos_iva;
                    for(var iva of this.ivasPDFFactura){
                        this.ivaPDFFactura = iva;
                        break;
                    }
                    this.totalesPDFFactura = siguiente.totales;
                    this.empresasPDFFactura = siguiente.empresas;
                    this.destinatariosPDFFactura = siguiente.companias;                    
                    for(var sers of Object.values(siguiente.series_compania_empresa)){
                        console.log('sers',sers);
                        this.seriesPDFFactura = sers[0].series;
                    }
                    console.log('seriesfact',this.seriesPDFFactura);
                    for(var serie of Object.values(this.seriesPDFFactura)){
                        this.seriePDFFactura = serie;
                        break;
                    }
                }else{
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcadosPDF,year:this.añoPDFFactura}); 
                    this.formaspagoPDFFactura = siguiente.formas_pago;
                    this.formapagoPDFFactura = siguiente.forma_pago_defecto;
                    this.cuentasPDFFactura = siguiente.cuentas_bancarias;
                    this.ivasPDFFactura = siguiente.tipos_iva;
                    for(var iv of this.ivasPDFFactura){
                        this.ivaPDFFactura = iv;
                        break;
                    }
                    this.totalesPDFFactura = siguiente.totales;
                    this.empresasPDFFactura = siguiente.empresas;
                    this.destinatariosPDFFactura = siguiente.companias;
                }
            }else{
                const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:[],year:this.añoPDFFactura}); 
                this.formaspagoPDFFactura = siguiente.formas_pago;
                this.formapagoPDFFactura = siguiente.forma_pago_defecto;
                this.cuentasPDFFactura = siguiente.cuentas_bancarias;
                this.ivasPDFFactura = siguiente.tipos_iva;
                for(var ia of this.ivasPDFFactura){
                        this.ivaPDFFactura = ia;
                        break;
                    }
                this.totalesPDFFactura = siguiente.totales;
                this.empresasPDFFactura = [];
                this.empresaPDFFactura = '';
                this.destinatariosPDFFactura = [];
                this.destinatarioPDFFactura = '';
            }
            this.fechaPDFFactura = this.fechaing(this.dia_actual);
        },
        obtenerNumero(){
            if(this.serieFactura != ''){
                this.numeroFactura = this.serieFactura.numero;
            }else{
                this.numeroFactura = '';
            }
        },
        obtenerNumeroCSV(){
            if(this.serieCSVFactura != ''){
                this.numeroCSVFactura = this.serieCSVFactura.numero;
            }else{
                this.numeroCSVFactura = '';
            }
        },
        obtenerNumeroCSVLD(){
            if(this.serieCSVLDFactura != ''){
                this.numeroCSVLDFactura = this.serieCSVLDFactura.numero;
            }else{
                this.numeroCSVLDFactura = '';
            }
        },
        obtenerNumeroPDF(){
            if(this.seriePDFFactura != ''){
                this.numeroPDFFactura = this.seriePDFFactura.numero;
            }else{
                this.numeroPDFFactura = '';
            }
        },
        async mostrarAlbaranesCSV(){
            this.spinner = true;
            this.albaranesCSV = [];
            this.erroresCSV = [];
            this.variasfacturasCSV = true;
            console.log('compsselecs',this.companiasSeleccionadas);
            for(var compania of this.companiasSeleccionadas){
                if(compania.opcion_varias_facturas != '1'){
                    this.variasfacturasCSV = false;
                    break;
                }
            }
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = '';
                console.log('compssel', this.companiasSeleccionadas);
                for(let compa of this.companiasSeleccionadas){
                    console.log('compa', compa);
                    if(companias == ''){
                        companias = compa.id;    
                    }else{
                        companias = companias + ', '+compa.id;
                    }
                }
            }
            if(this.archivoSubidocsv == ''){
                alert("Debe seleccionar algún archivo csv");
                return;
            }
            if(this.serieCSVseleccionada == ''){
                alert("Debe seleccionar alguna serie de facturación");
                return;
            }
            console.log('archcsv',this.archivoSubidocsv);
            this.albaranes = [];
            const api = new PwgsApi();
            const formData = new FormData();
            formData.append('id_empresa', this.empresaselec);
            if(this.incluiravisoscsv){
                formData.append('incluir_avisos', '1');
            }
            formData.append('ids_companias', companias);
            formData.append('csv', this.archivoSubidocsv);
            try{
                const resp = await api.post('facturacion-albaranes/importacion-csv',formData);
                console.log('resp', resp);
                for(let alb of Object.entries(resp.albaranes)) {
                    if(alb[0] == 'importe_bases'){
                        this.importe_base_csv=alb[1];
                    }else if(alb[0] == 'importe_totales'){
                        console.log();
                    }else{
                        if(alb[1].factura){
                            alb[1].marcada = false;
                        }
                        this.albaranesCSV.push(alb[1]);
                    }
                }
                if(resp.errores){
                    this.erroresCSV = resp.errores;
                    for(let errors of this.erroresCSV){
                        
                        console.log('error', errors.error);
                        var newerror = errors.error.replaceAll('\\u20ac' , '€');
                        console.log('newerror', newerror);
                        errors.error = newerror;
                    }
                }
                if(resp.albaranes_csv){
                    this.albaranes_csv = resp.albaranes_csv;
                }
            }catch(e){
                console.log('Error:', e);
                this.spinner = false;
            }finally{
                this.obtenerDatosFacturaCSV();
                this.albaranesVisibles = false;
                this.albaranesCSVLDVisibles = false;
                this.albaranesPDFVisibles = false;
                this.albaranesCSVVisibles = true;           
                this.spinner = false;
            }
        },
        async mostrarAlbaranesCSVLD(){
            this.spinner = true;
            this.albaranesCSVLD = [];
            this.erroresCSVLD = [];
            this.variasfacturasCSVLD = true;
            console.log('compsselecs',this.companiasSeleccionadas);
            for(var compania of this.companiasSeleccionadas){
                if(compania.opcion_varias_facturas != '1'){
                    this.variasfacturasCSVLD = false;
                    break;
                }
            }
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = '';
                console.log('compssel', this.companiasSeleccionadas);
                for(let compa of this.companiasSeleccionadas){
                    console.log('compa', compa);
                    if(companias == ''){
                        companias = compa.id;    
                    }else{
                        companias = companias + ', '+compa.id;
                    }
                }
            }
            if(this.archivoSubidocsvld == ''){
                alert("Debe seleccionar algún archivo csv");
                return;
            }
            if(this.serieCSVLDseleccionada == ''){
                alert("Debe seleccionar alguna serie de facturación");
                return;
            }
            console.log('archcsv',this.archivoSubidocsvld);
            this.albaranes = [];
            const api = new PwgsApi();
            const formData = new FormData();
            formData.append('id_empresa', this.empresaselec);
            if(this.incluiravisoscsvld){
                formData.append('incluir_avisos', '1');
            }
            formData.append('ids_companias', companias);
            formData.append('csv', this.archivoSubidocsvld);
            try{
                const resp = await api.post('facturacion-albaranes/importacion-csv-ld',formData);
                console.log('resp', resp);
                for(let alb of Object.entries(resp.albaranes)) {
                    if(alb[0] == 'importe_bases'){
                        this.importe_base_csvld=alb[1];
                    }else if(alb[0] == 'importe_totales'){
                        console.log();
                    }else{
                        if(alb[1].factura){
                            alb[1].marcada = false;
                        }
                        this.albaranesCSVLD.push(alb[1]);
                    }
                }
                if(resp.errores){
                    this.erroresCSVLD = resp.errores;
                    for(let errors of this.erroresCSVLD){
                        var err = errors.error
                        console.log('error', err);
                        var newerror = err.replaceAll('\\u20ac' , '€');
                        console.log('newerror', newerror);
                        errors.error = newerror;
                    }
                }       
                if(resp.albaranes_csv){
                    this.albaranes_csvld = resp.albaranes_csv;
                }     
            }catch(e){
                console.log('error', e);
                this.spinner = false;
            }finally{
                this.obtenerDatosFacturaCSVLD();
                this.albaranesVisibles = false;
                this.albaranesCSVVisibles = false;
                this.albaranesPDFVisibles = false;
                this.albaranesCSVLDVisibles = true;
                this.spinner = false;
            }
        },
        async mostrarAlbaranesPDF(){
            this.spinner = true;
            this.albaranesPDF = [];
            this.erroresPDF = [];
            this.variasfacturasPDF = true;
            console.log('compsselecs',this.companiasSeleccionadas);
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = '';
                console.log('compssel', this.companiasSeleccionadas);
                for(let compa of this.companiasSeleccionadas){
                    console.log('compa', compa);
                    if(companias == ''){
                        companias = compa.id;    
                    }else{
                        companias = companias + ', '+compa.id;
                    }
                }
            }
            if(this.archivoSubidopdf == ''){
                alert("Debe seleccionar algún archivo PDF");
                return;
            }
            console.log('archcsv',this.archivoSubidopdf);
            this.albaranes = [];
            const api = new PwgsApi();
            const formData = new FormData();
            formData.append('id_empresa', this.empresaselec);
            if(this.incluirelacionadospdf){
                formData.append('incluir_relacionados', '1');
            }
            formData.append('ids_companias', companias);
            formData.append('pdf', this.archivoSubidopdf);
            try{
                const resp = await api.post('facturacion-albaranes/importacion-pdf',formData);
                console.log('resp', resp);
                for(let alb of Object.entries(resp.albaranes)) {
                    if(alb[0] == 'importe_bases'){
                        this.importe_base_pdf=alb[1];
                    }else if(alb[0] == 'importe_totales'){
                        console.log();
                    }else{
                        if(alb[1].factura){
                            alb[1].marcada = false;
                        }
                        this.albaranesPDF.push(alb[1]);
                    }
                }
                if(resp.errores){
                    this.erroresPDF = resp.errores;
                    for(let errors of this.erroresPDF){
                        var err = errors.error
                        console.log('error', err);
                        var newerror = err.replaceAll('\\u20ac' , '€');
                        console.log('newerror', newerror);
                        errors.error = newerror;
                    }
                }            
            }catch(e){
                console.error('Error:', e);
                this.spinner = false;
            }finally{
                this.albaranesVisibles = false;
                this.albaranesCSVVisibles = false;
                this.albaranesCSVLDVisibles = false;
                this.albaranesPDFVisibles = true;
                this.spinner = false;
            }
        },
        async mostrarAlbaranesNumerosExpe(){
            this.spinner = true;
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = [];
                for(let compa of this.companiasSeleccionadas){
                    companias.push(compa.id);
                }
            }
            this.albaranes = [];
            const api = new PwgsApi();
            var body = {id_empresa:this.empresaselec, ids_companias:companias, fecha_inicio:this.fechaesp(this.fecha_inicio), fecha_fin:this.fechaesp(this.fecha_fin)};
            if(this.numerosExpedientes){
                body.expedientes = this.numerosExpedientes.split(";");
            }
            if(this.incluirelacionados){
                body.incluir_relacionados = 1;
            }
            try{
                const resp = await api.post('facturacion-albaranes/expedientes', body);
                console.log('respusetaNumeros',resp);
                for(let alb of Object.entries(resp)){
                    if(alb[0] == 'importe_totales'){
                        this.importe_total = alb[1];
                    }else if(alb[0] == 'importe_bases'){
                        this.importe_base = alb[1];
                    }else{
                        this.albaranes.push(alb[1]);
                    }
                }
            }catch(e){
                console.error('Error:', e);
                this.spinner = false;
            }finally{
                this.albaranesMarcados = [];
                this.facturaGenerada = false;
                this.urlFacturaCreada = '';
                this.obtenerDatosFactura();
                this.albaranesVisibles = false;
                this.albaranesCSVVisibles = false;
                this.albaranesCSVLDVisibles = false;
                this.albaranesVisibles = true;
                this.spinner = false;
            }
        },
        comprobarPestañas(){
            this.mostrarCSV=false;
            this.mostrarCSVLD=false;
            this.mostrarPDF=false;
            this.series = [];
            this.incluiravisoscsv=false;
            this.incluiravisoscsvld=false;
            this.incluirelacionados = false;
            this.mostraravisos=false;
            this.mostrarrelacionados=false;
            this.serieCSVLDseleccionada = '';
            this.serieCSVseleccionada='';
            $('.nav-link').removeClass('active');
            $(`[href="#busqueda"]`).addClass('active');
            $('.tab-pane').removeClass('active');
            $(`#busqueda`).addClass('active');
            if(this.companiasSeleccionadas.length > 0){
                var numero = this.companiasSeleccionadas.length;
                var csv = 0;
                var csVLD = 0;
                var pdf = 0;
                var avisos = 0;
                var relacionados = 0;
                for(let compa of this.companiasSeleccionadas){
                    if(compa.incluir_avisos=='1'){
                        avisos++;
                    }
                    if(compa.incluir_relacionados=='1'){
                        relacionados++;
                    }
                    if(compa.tipos_importacion_disponibles.includes('importacion-csv')){
                        csv++;
                    }
                    if(compa.tipos_importacion_disponibles.includes('importacion-csv-ld')){
                        csVLD++;
                    }
                    if(compa.tipos_importacion_disponibles.includes('importacion-pdf')){
                        pdf++;
                    }
                    for(let linea of compa.lineas_facturacion){
                        this.series.push(linea);
                    }
                }
                if(avisos == numero){
                    this.mostraravisos = true;
                }
                if(relacionados == numero){
                    this.mostrarrelacionados = true;
                }
                if(csv == numero){
                    this.mostrarCSV = true;
                }
                if(csVLD == numero){
                    this.mostrarCSVLD = true;
                }
                if(pdf == numero){
                    this.mostrarPDF = true;
                }
            }
        },
        async obtenerDatos(){
            const api = new PwgsApi();            
            this.empresas = await api.get('empresas/todas');
        },
        async obtenerCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('facturacion-albaranes/companias/'+this.empresaselec);
            this.companias = resp.datos;
        },
        fechaActual() {
        const hoy = new Date();
        this.fecha_inicio = this.fechaing(hoy.toLocaleDateString());
        this.fecha_fin = this.fechaing(hoy.toLocaleDateString());
        this.añoActual();
        },
        añoActual() {
            var hoy = new Date();
            this.dia_actual = hoy.toLocaleDateString();
            this.año_actual = new Date().getFullYear();
            this.añosFacturas.push(this.año_actual);
            this.añosFacturas.push(this.año_actual-1);
            this.añosFacturas.push(this.año_actual-2);
            this.añosCSVFacturas.push(this.año_actual);
            this.añosCSVFacturas.push(this.año_actual-1);
            this.añosCSVFacturas.push(this.año_actual-2);
            this.añosCSVFiscal.push(this.año_actual);
            this.añosCSVFiscal.push(this.año_actual-1);
            this.añosCSVLDFacturas.push(this.año_actual);
            this.añosCSVLDFacturas.push(this.año_actual-1);
            this.añosCSVLDFacturas.push(this.año_actual-2);
            this.añosCSVLDFiscal.push(this.año_actual);
            this.añosCSVLDFiscal.push(this.año_actual-1);
            this.añosPDFFacturas.push(this.año_actual-2);
            this.añosPDFFacturas.push(this.año_actual);
            this.añosPDFFacturas.push(this.año_actual-1);
            this.añosPDFFacturas.push(this.año_actual-2);
            this.añoFactura = this.año_actual;
            this.añoCSVFactura = this.año_actual; 
            this.añoCSVFiscal = this.año_actual; 
            this.añoCSVLDFiscal = this.año_actual; 
            this.añoCSVLDFactura = this.año_actual;
            this.añoPDFFactura = this.año_actual;       
        },
        fechaing(fecha) {
        const [dia, mes, año] = fecha.split("/");
        return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },

        fechaesp(fecha) {
        const [año, mes, dia] = fecha.split("-");
        return `${dia}/${mes}/${año}`;
        },
        async cargarImpuestos(){
            const api = new PwgsApi();
            const ress = await api.get('impuestos');
            this.impuestos = ress.datos;
        }
    },
    mounted(){
        this.obtenerDatos();
        this.fechaActual();
        this.cargarImpuestos();
    },
    watch:{
        destinatarioFactura(){
            this.obtenerDatosFactura();
        },
        serieFactura(){
            this.obtenerNumero();
        },
        añoFactura(){
            this.obtenerDatosFactura();
        },
        destinatarioCSVFactura(){
            this.obtenerDatosFacturaCSV();
        },
        serieCSVFactura(){
            this.obtenerNumeroCSV();
        },
        añoCSVFactura(){
            this.obtenerDatosFacturaCSV();
        },
        destinatarioCSVLDFactura(){
            this.obtenerDatosFacturaCSVLD();
        },
        serieCSVLDFactura(){
            this.obtenerNumeroCSVLD();
        },
        añoCSVLDFactura(){
            this.obtenerDatosFacturaCSVLD();
        },
        destinatarioPDFFactura(){
            this.obtenerDatosFacturaPDF();
        },
        seriePDFFactura(){
            this.obtenerNumeroPDF();
        },
        añoPDFFactura(){
            this.obtenerDatosFacturaPDF();
        },
        checkTodas(){
            this.marcarTodas();
        },
        checkTodasCSV(){
            this.marcarTodasCSV();
        },
        checkTodasCSVLD(){
            this.marcarTodasCSVLD();
        },
        checkTodasPDF(){
            this.marcarTodasPDF();
        },
        empresaselec(value){
            this.companias = null;
            this.companiasSeleccionadas = [];
            if(value){
                this.obtenerCompanias();
            }
        },
        companiasSeleccionadas(){
            this.comprobarPestañas();
        }
    }
}
</script>
<style >
.difuminado{
        filter: blur(6px);
            pointer-events: none;
            -webkit-user-select: none;
                /* Safari */
            -ms-user-select: none;
                /* IE 10 and IE 11 */
            user-select: none;
                /* Standard syntax */
}
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #d9e3ff;
    border-right-color: #007bff;
    animation: spinner-d3wgkg 1s infinite linear;
    position: absolute;
    z-index:1000;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
         
}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}
</style>